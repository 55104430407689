@import "src/styles";
.container {
  display: grid;
  grid-template-areas:
    'right'
    'left';
  align-items: center;
  text-align: center;
  gap: $spacing-32;
  padding: {
    top: $spacing-64;
    bottom: $spacing-64;
  }

  @include breakpoint-md {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'left right';
    margin: $spacing-64 auto;
  }

  & .left {
    grid-area: left;

    @include breakpoint-md {
      text-align: left;
    }

    & .exclamationIcon {
      display: none;
      height: 48px;
      width: 48px;
      fill: $color-primary-main;
      margin-bottom: $spacing-32;

      @include breakpoint-md {
        display: block;
      }
    }

    & .header {
      margin-bottom: $spacing-32;
    }

    & .link {
      display: inline-block;
      margin-top: $spacing-32;
    }
  }

  & .right {
    grid-area: right;
    display: flex;
    justify-content: center;

    & .repairIcon {
      max-width: 300px;
      max-height: 260px;
    }

    @include breakpoint-md {
      justify-content: end;

      & .repairIcon {
        max-width: none;
      }
    }
  }
}
